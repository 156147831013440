var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c("c-tab", {
          attrs: {
            dense: true,
            tabItems: _vm.tabItems,
            inlineLabel: true,
            setHeight: "500px",
          },
          on: {
            "update:tabItems": function ($event) {
              _vm.tabItems = $event
            },
            "update:tab-items": function ($event) {
              _vm.tabItems = $event
            },
            tabClick: _vm.tabClick,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (tab) {
                return [
                  _c(tab.component, {
                    tag: "component",
                    attrs: {
                      hhmHazardousMachineryId:
                        _vm.popupParam.hhmHazardousMachineryId,
                      count: _vm.count,
                      tabParam: _vm.tabParam,
                    },
                    on: {
                      "update:hhmHazardousMachineryId": function ($event) {
                        return _vm.$set(
                          _vm.popupParam,
                          "hhmHazardousMachineryId",
                          $event
                        )
                      },
                      "update:hhm-hazardous-machinery-id": function ($event) {
                        return _vm.$set(
                          _vm.popupParam,
                          "hhmHazardousMachineryId",
                          $event
                        )
                      },
                      changeStatus: _vm.changeStatus,
                      transInfo: _vm.transInfo,
                    },
                  }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }