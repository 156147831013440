<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-tab
        :dense="true"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        setHeight="500px"
        v-model="tab"
        @tabClick="tabClick"
      >
        <template v-slot:default="tab">
          <component
            :hhmHazardousMachineryId.sync="popupParam.hhmHazardousMachineryId"
            :count="count"
            :tabParam="tabParam"
            :is="tab.component"
            @changeStatus="changeStatus"
            @transInfo="transInfo"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'hazard-equipment-class-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        hhmHazardousMachineryId: '',
      }),
    },
  },
  data() {
    return {
      tabDisabled: false,
      tab: 'hazardEquipmentInfo',
      tabItems: [],
      addTabItems: [],
      tabParam: {
        equipmentCd: '',
        hazardEquipmentHistoryModels: [],
        equipmentImproveModels: [],
      },
      count: 0,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.tabItems = [
        { name: 'hazardEquipmentInfo', icon: 'info', label: '유해위험기계기구 정보', component: () => import(`${'./hazardEquipmentInfo.vue'}`) }
      ];
      this.addTabItems = [
        { name: 'hazardCausePrevention', icon: 'add_circle_outline', label: '개선관리', component: () => import(`${'./hazardCausePrevention.vue'}`) },
        { name: 'hazardEquipmentHistory', icon: 'construction', label: '검사이력 관리', component: () => import(`${'./hazardEquipmentHistory.vue'}`) },
      ];
      if (this.popupParam.hhmHazardousMachineryId) {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    changeStatus() {
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
    transInfo(data) {
      this.tabParam = data
    },
    tabClick() {
      this.count++;
    }
  }
};
</script>